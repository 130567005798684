<template>
  <div>
    <confirm-adjust
      :store-module-name="storeModuleName"
      :is-modal-active.sync="isModalConfirmAdjustActive"
      :show.sync="dataLoading"
      :data="modalConfirmAdjustData"
    />
    <b-modal
      :visible="isModalActive"
      :title="
        `${$t('Adjust quantity')} : ${showFromCurrentLanguage(name)} (${code})`
      "
      ok-title="Accept"
      size="lg"
      @change="val => $emit('update:is-modal-active', val)"
    >
      <b-overlay
        :show="dataLoading"
        rounded="sm"
      >
        <b-row class="mb-2">
          <b-col cols="6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Search') + '...'"
              @input="typing($event)"
            />
          </b-col>
        </b-row>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <!-- Column: Images -->
          <template #cell(images)="data">
            <b-row>
              <b-img
                v-if="data.item.images.length > 0"
                :src="data.item.images[0]"
                fluid
                thumbnail
                width="100px"
                :alt="'Product_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Product_' + data.item._id"
              />
            </b-row>
          </template>

          <template #cell(name)="data">
            <p>{{ showFromCurrentLanguage(data.item.name) }}</p>
          </template>

          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-dark"
              class="d-flex"
              @click="adjustThisWarehouse(data.item)"
            >
              <span class="align-middle">{{ $t('Adjust') }}</span>
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <template #modal-footer>
        <div class="w-100">
          <b-pagination
            v-model="currentPage"
            :total-rows="queriedItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="nextPage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BTable,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import ConfirmAdjust from './components/ConfirmAdjust.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    BPagination,
    ConfirmAdjust,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      dataId: null,
      dataLoading: false,
      code: '',
      name: [],
      searchQuery: '',
      modalConfirmAdjustData: {},
      isModalConfirmAdjustActive: false,
      required,
    }
  },
  computed: {
    respData() {
      return store.state[this.storeModuleName].respDataStockInWarehouse != null
        ? store.state[this.storeModuleName].respDataStockInWarehouse.data
        : []
    },
    queriedItems() {
      return store.state[this.storeModuleName].respDataStockInWarehouse != null
        ? store.state[this.storeModuleName].respDataStockInWarehouse.max
        : 0
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    tableColumns() {
      return [
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'stock', label: this.$t('Stock'), sortable: false },
        { key: 'action', label: '', sortable: false },
      ]
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues()
        } else {
          const { _id, code, name } = this.data
          this.dataId = _id
          this.code = code
          this.name = name
          this.firstGet()
        }
      }
    },
    isModalConfirmAdjustActive(val) {
      if (!val) {
        this.firstGet()
      }
    },
  },
  methods: {
    adjustThisWarehouse(item) {
      this.modalConfirmAdjustData = {
        product: {
          _id: this.dataId,
          code: this.code,
          name: this.name,
        },
        warehouse: item,
      }
      this.isModalConfirmAdjustActive = true
    },
    firstGet() {
      const obj = {
        id: this.dataId,
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        warehouse: this.warehouse,
      }
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/getStockInWarehouse`, obj)
        .then(result => {
          this.dataLoading = false

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.dataLoading = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    typing(value) {
      this.searchQuery = value
      this.firstGet()
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      this.firstGet()
    },
    nextPage(page) {
      this.currentPage = page
      this.firstGet()
    },
    initValues() {
      this.dataId = null
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
