<template>
  <b-modal
    :visible="isModalActive"
    :title="`${$t('Adjust the amount remaining in the inventory')}`"
    ok-title="Accept"
    size="lg"
    @change="val => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ $t('Product') }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ showFromCurrentLanguage(product.name) }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ $t('Warehouse/Branch') }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ showFromCurrentLanguage(warehouse.name) }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ $t('Current inventory amount') }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ warehouse.stock }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ $t('Adjusted remaining amount') }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <cleave
            v-model="adjustAmount"
            class="form-control"
            :raw="false"
            :options="optionCleave.number"
            :placeholder="$t('Adjusted remaining amount')"
          />
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <span>
            {{ $t('Remark') }}
          </span>
        </b-col>
        <b-col
          cols="6"
          class="mb-2"
        >
          <b-form-input
            v-model="remark"
            class="d-inline-block"
            :placeholder="$t('Remark')"
          />
        </b-col>
      </b-row>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            @click="save()"
          >
            {{ $t('Save') }}
            <feather-icon
              icon="ShoppingBagIcon"
              size="12"
            />
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BTable,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    BPagination,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataLoading: false,
      adjustAmount: 0,
      remark: '',
      product: {},
      warehouse: {},
      required,
    }
  },
  computed: {},
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues()
        } else {
          const { product, warehouse } = this.data
          this.product = product
          this.warehouse = warehouse
          this.adjustAmount = warehouse.stock
          this.firstGet()
        }
      }
    },
  },
  methods: {
    firstGet() {},
    initValues() {
      this.product = {}
      this.warehouse = {}
      this.adjustAmount = 0
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    save(){
      const obj = {
        warehouseId: this.warehouse._id,
        productId: this.product._id,
        amount: this.adjustAmount,
        remark: this.remark,
      }
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/adjust`, obj)
        .then(result => {
          this.dataLoading = false
          this.$emit('update:is-modal-active', false)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.dataLoading = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
